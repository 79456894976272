.swiper-pagination-bullet {
  background-color: #0A5984 !important;
  opacity: 0.6 !important;
  width: 20px !important;
  height: 20px !important;
  border: 2px solid #FFF;
}

.swiper-pagination-bullet-active {
  background-color: #033652 !important;
  opacity: 1 !important;
}

.swiper-button-prev::after, .swiper-button-next::after {
  color: #FFF;
}

.swiper-button-prev {
  left: 25px !important;
  padding: 20px !important;
}

.swiper-button-next {
  right: 25px !important;
  padding: 20px !important;
}

@media (max-width: 720px) {
  .swiper-pagination-bullet {
    width: 18px !important;
    height: 18px !important;
  }

  .swiper-button-prev {
    left: 15px !important;
    padding: 10px !important;
  }

  .swiper-button-next {
    right: 15px !important;
    padding: 10px !important;
  }
  
  .swiper-button-prev::after, .swiper-button-next::after {
    color: #1A202C;
    font-size: 30px !important;
  }
} 